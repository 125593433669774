/**
 * @todo
 * 1. Conditionally smooth rendering
 * 2. Conditionally render content
 */

// bounty
import {setTimeout} from '@acng/frontend-bounty/dom/window.js';
import {set as setStyle} from '@acng/frontend-bounty/style/element.js';
import {get, remove, set} from '@acng/frontend-bounty/dom/attribute.js';
import {iso, parse} from '@acng/frontend-bounty/std/date.js';
import {isNaN, ceil, floor} from '@acng/frontend-bounty/std/number.js';
import {isDefined} from '@acng/frontend-bounty/std/value.js';
import {HTMLElement, def, con, dis, obs, att} from '@acng/frontend-bounty/dom/custom.js';
// stargazer & relativity & rubicon
import {Engine, disconnect} from '@acng/frontend-stargazer';
import {Watch} from '@acng/frontend-relativity';
import {IS, OBJECT, OPTIONAL, guard} from '@acng/frontend-rubicon';
// enterprise
import {swapClass} from '../service/style.js';
import {STYLE_INACTIVE} from '../config/style.js';
import {STYLE_INIT} from '../style/flags.js';
import {ctxTiming} from '../context/timing.js';
import {now} from '@acng/frontend-bounty/timing/now.js';
import {isConnected} from '@acng/frontend-bounty/dom/type.js';
import {TIMING_END, TIMING_START} from '../attribute/common.js';

/**
 * @group DOM Element
 */
export const NOVA_TIMER = 'nova-timer';

/**
 * @group CSS Custom Property
 */
export const __TIMER_RATIO = '--timer-ratio';

(() => {
  /**
   * @param {Engine} engine
   * @param {HTMLElement} timer
   */
  const reset = (engine, timer) => {
    disconnect(engine);

    const startAt = parse(get(timer, TIMING_START)) ?? NaN; // -Infinity;
    const endAt = parse(get(timer, TIMING_END)) ?? NaN; // Infinity;
    const timing = !isNaN(startAt + endAt);

    swapClass(timer, STYLE_INIT, !timing);

    if (!timing) {
      return;
    }

    engine.toElement(timer);

    //const [title, startAt, endAt] = timing;

    const render = () => {
      ASSERT: {
        guard(
          engine.nodes,
          OBJECT({
            timeLeft: OPTIONAL(IS(Node)),
          }),
          `${NOVA_TIMER} transcluded template`
        );

        if (endAt < startAt) {
          console.warn(`${NOVA_TIMER} endAt before startAt`, {timer: this, startAt, endAt});
        }
      }

      const range = endAt - startAt;
      const diff = Math.max(0, endAt - now());
      const h = floor(diff / 36e5);
      const m = `${floor((diff % 36e5) / 6e4)}`.padStart(2, '0');
      const s = `${ceil((diff % 6e4) / 1e3)}`.padStart(2, '0');
      const ratio = diff / range;
      /* TODO(2)
      timeStart
      timeElapsed
      timeLeft
      timeEnd
      title
      */
      if (engine.nodes.timeLeft) {
        engine.nodes.timeLeft.textContent = `${h ? h : ''}${h ? ':' : ''}${m}:${s}`;
      }

      setStyle(timer, __TIMER_RATIO, `${ratio}`);
    };

    const update = () => {
      if (isConnected(timer)) {
        render();

        if (swapClass(timer, STYLE_INACTIVE, endAt <= now())) {
          return;
        }

        delayedUpdate();
      }
      /* TODO(1)
      requestAnimationFrame((timestamp) => {
        if (timer.isConnected) {
          if (timestamp - updated >= 100) {
            render();
            updated = timestamp;
          }

          update();
        }
      });
      */
    };

    const delayedUpdate = () => setTimeout(update, 1e3 - (now() % 1e3));

    update();
  };

  const template = Engine.Transclude(NOVA_TIMER);
  const watch = Watch(NOVA_TIMER, ctxTiming);

  def(
    NOVA_TIMER,
    class extends HTMLElement {
      static [obs] = [TIMING_START, TIMING_END];

      #engine = Engine.root;

      [att]() {
        if (this.#engine !== Engine.root) {
          reset(this.#engine, this);
        }
      }

      [con]() {
        const engine = new Engine(template(this));

        try {
          watch(this, (timer, timing, previous) => {
            if (timing) {
              const [, startAt, endAt] = timing;
              set(timer, TIMING_START, iso(startAt));
              set(timer, TIMING_END, iso(endAt));
            } else if (isDefined(previous)) {
              remove(timer, TIMING_START);
              remove(timer, TIMING_END);
            }
          });
        } catch (reason) {
        } finally {
          reset(engine, this);
        }

        this.#engine = engine;
      }

      [dis]() {
        disconnect(this.#engine);
        this.#engine = Engine.root;
      }
    }
  );
})();
