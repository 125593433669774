import {path, alias, expr, middleware, validate, on} from '@acng/frontend-voyager';
import {replaceUrl} from '@acng/frontend-relativity/history';

import {rootRoute} from 'acng/core/config/routes';
import {amateurRoute} from 'acng/amateurPool/config/routes';
import {mainView, stickyView} from 'acng/layout/service/sequence.js';
import {onlyUserOrPreview} from 'acng/userPool/config/middleware';

import {moviePoolFeature} from './feature.js';

import {LocalActor} from '@acng/frontend-relativity';
import {ctxGallery} from 'acng/core/context/gallery.js';
import {documentElement} from '@acng/frontend-bounty';
import {noop} from '@acng/frontend-bounty/std/value.js';
import {get} from '../service/movie.js';

export const moviesRoute = rootRoute.create(
  onlyUserOrPreview, //
  expr(/^(?:\/sedcard\/.+)?\/movies/y),
  mainView(moviePoolFeature, 'movies'),
  // TODO keep old route (deeplinks case) , make redirect to new default movie/pictures list route
  // TODO alias is required by onswCategorySelect in sidebar
  alias('movies')
);

rootRoute.create(
  path('/movies/{type}'),
  validate(({type}) => /^(top|new|classics)$/.test(/** @type {string} */ (type))),
  stickyView(moviePoolFeature, 'list-header'),
);

const provideMovie = LocalActor(ctxGallery);

/**
 * @type {(element: Element) => void}
 */
let unlock = noop;

amateurRoute.create(
  path('/movie/{movieId}'), //
  mainView(moviePoolFeature, 'movie'),
  alias('movieDetail'),
  on('leave', () => {
    unlock?.(documentElement);
    unlock = noop;
  }),
  middleware((next, prev) => {
    if (next.movieId == prev.movieId) {
      next.movie = prev.movie;
      return true;
    }
    return (async () => {
      try {
        const movie = await get(Number(next.movieId));
        next.movie = movie;
        unlock = provideMovie(documentElement, movie);
      } catch (reason) {
        replaceUrl('/#/');
        throw reason;
      }
    })();
  })
);
