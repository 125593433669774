import {ElementSetup, Engine} from '@acng/frontend-stargazer';
import {Watch} from '@acng/frontend-relativity';
import {createPerceptiveHTML, createStream} from '@acng/frontend-discovery';
import {isDefined} from '@acng/frontend-bounty/std/value.js';
import {HTMLElement, con, def, dis} from '@acng/frontend-bounty/dom/custom.js';

import {ctxList} from '../context/list';
import {onClick} from '../service/worker';

/**
 * @group DOM Element
 */
export const NOVA_LIST = 'nova-list';

ElementSetup(NOVA_LIST, (name) => {
  const transclude = Engine.Transclude(name);
  const watchList = Watch(name, ctxList);

  /**
   * @template T
   */
  class ListElement extends HTMLElement {
    /**
     * @type {import('../context/list').List<T> | undefined}
     */
    #list;
    #engine = Engine.root;
    #stream = createStream([], async (offset) => this.#list?.more(offset) ?? []);
    #warpdrive = createPerceptiveHTML(this, this.#stream, async (entry, loader) => {
      const config = await this.#list?.config(entry);

      if (loader.target) {
        const click = this.#list?.click;

        if (click) {
          onClick(loader.target, (_element, evt) => click(entry, evt));
        }

        this.#engine.toElement(loader.target, config);
      }
    });

    [con]() {
      this.#engine = transclude(this);

      watchList(this, (_element, list, previous) => {
        this.#list = list;
        if (isDefined(previous)) {
          this.#stream.streamReset([]);
        }

        this.#warpdrive.connect();
      });
    }

    [dis]() {
      this.#warpdrive.disconnect();
      this.#engine.disconnect();
    }
  }

  def(name, ListElement);
});
