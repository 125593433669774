/**
 * Provide FMOTD data.
 * @module
 */

import {GlobalContext, GlobalActor} from '@acng/frontend-relativity';
import {now, parse} from '@acng/frontend-bounty/std/date.js';
import {min} from '@acng/frontend-bounty/std/number.js';
import {setTimeout} from '@acng/frontend-bounty/dom/window.js';
import {guard} from '@acng/frontend-rubicon';

import {cdn} from '../../core/service/cdn.js';
import {FMOTD_DATA_LIST} from '../model/data.js';
import {each} from '@acng/frontend-bounty/std/array.js';

/**
 * @typedef FreeMovieData
 * @property {number} id
 * @property {number} amateur_id
 * @property {number} free_since
 * @property {number} free_until
 * @property {boolean} use_profile_image
 */

/**
 * @type {Global<FreeMovieData[]>}
 */
export const ctxFreeMovieList = GlobalContext([]);

/**
 * @type {() => Promise<void>}
 */
export const init = (() => {
  /**
   * The last loaded data is considered fresh and therefore kept until that time.
   */
  let expireDate = 0;

  const broadcast = GlobalActor(ctxFreeMovieList);

  const update = async () => {
    const currentDate = now();

    if (expireDate > currentDate) {
      return;
    }

    // Synchronously-Temporarily: Be reentrant here for sure!
    expireDate = currentDate + 6e5;

    /**
     * @param {number} adjustExpireDate
     * @param {FreeMovieData} movie
     * @returns {number}
     */
    const firstUpcomingDate = (adjustExpireDate, movie) => {
      const {free_since, free_until} = movie;

      return min(
        free_since > currentDate ? free_since : adjustExpireDate,
        free_until > currentDate ? free_until : adjustExpireDate
      );
    };

    const movieList = await cdn('/api/movie/free');

    // @ts-ignore TODO
    each(movieList, (item) => {
      item.free_since = parse(item.free_since);
      item.free_until = parse(item.free_until);
    });

    ASSERT: guard(movieList, FMOTD_DATA_LIST, 'FMOTD list from ACNG backend');

    expireDate = movieList.reduce(firstUpcomingDate, expireDate);

    DEBUG: console.log('FMOTD expire', new Date(expireDate));

    setTimeout(update, expireDate - currentDate);

    DEBUG: if (!movieList.length) {
      console.warn('fmotd list is empty');
    }

    broadcast(movieList);
  };

  return update;
})();
