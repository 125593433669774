// @ts-nocheck do not fix deprecated tile
import angular from 'angular';
import {LocalActor} from '@acng/frontend-relativity';
import {ctxGallery} from 'acng/core/context/gallery.js';
import {get} from '../service/movie.js';

const provideMovie = LocalActor(ctxGallery);

angular.module('moviePool')

  .factory('MovieTile', ['AmateurTile', 'Movie', (AmateurTile, Movie) => {
    class MovieTile extends AmateurTile
    {
      load(scope, movie_id) {
        return get(movie_id)
          .then(movie => {
            provideMovie(this.el, movie);
            this.apply({
              fnMain: 'detail',
              movie: movie,
              title: movie.getTitle(),
              mediaType: 'Movie',
              mediaId: movie.id,
              mediaSetId: movie.set_id,
              publishedAt: new Date(movie.published).toLocaleString(document.body.lang, {dateStyle: 'medium', timeStyle: 'short'}),
              image: movie.getImageUrl(),
              click: () => movie.goto(),
              player: () => movie.goto(),
              desc: movie.getDescription()
            });
            return super.load(scope, `${movie.user_id}`);
          })
          .catch(err => {
            if (err.status == 404) {
              Movie.doNotShow.push(movie_id);
            }
            throw err;
          });
      }
      hookname() {
        return 'media';
      }
    }
    return MovieTile;
  }]);
