import {AttributeSetup, AttributeLink, task} from '@acng/frontend-stargazer';
import {createMap, set, get} from '@acng/frontend-bounty/collection.js';
import {each} from '@acng/frontend-bounty/std/array.js';
import {HIDDEN, set as attr} from '@acng/frontend-bounty/dom/attribute.js';
import {GlobalWatch, WatchGroup} from '@acng/frontend-relativity';
import {IS, guard} from '@acng/frontend-rubicon';
import {remove} from '@acng/frontend-bounty/dom/move.js';

import {ctxGallery} from '../../core/context/gallery.js';
import {Movie} from '../../moviePool/model/movie.js';
import {TIMING_END, TIMING_START} from '../../layout/attribute/index.js';
import {feature} from '../index.js';
import {ctxFreeMovieList, init} from '../context/list.js';
import {iso} from '@acng/frontend-bounty/std/date.js';

/**
 * @group DOM Attribute
 */
export const FMOTD_COUNTDOWN = 'fmotd-countdown';

AttributeSetup(FMOTD_COUNTDOWN, (name) => {
  // bootstrapAttribute
  /**
   * @type {Map<Movie["id"], string>}
   */
  const timings = createMap();
  const watch = WatchGroup(`[${name}]`, ctxGallery, ctxFreeMovieList);

  GlobalWatch(ctxFreeMovieList)((list) => each(list, (item) => set(timings, item.id, iso(item.free_since))));

  AttributeLink(
    // linkAttribute
    name,
    () => (element) =>
      watch(element, (element, [movie]) => {
        ASSERT: guard(movie, IS(Movie));
        attr(element, TIMING_END, get(timings, movie.id));
        //attr(element, TIMING_END, `${Date.now() + 30000000}`); // Test countdown
      })
  );

  return (element, _value, compiler) => {
    if (feature[HIDDEN]) {
      remove(element);
      return;
    }

    attr(element, TIMING_START, '0');
    task(compiler, init());
  };
});
