/**
 * @module
 * @author Jacob Viertel <jv@onscreen.net>
 */

import {fetch} from '@acng/frontend-bounty/net/fetch.js';
import {API_cdn} from './env.js';
import {handleCfChallenge} from './backend/cf-challenge.js';

/**
 * Perform a HTTP GET via CDN with handled cloudflare challenge and JSON response.
 *
 * @throws
 * response with non-"OK" status and {@link fetch} exceptions
 *
 * @param {string} path
 * is prefixed with the {@link API_cdn}
 *
 * @param {(response: Response) => Promise<Response> | Response | void} [intercept]
 * handle the response before the body is decoded.
 *
 * @returns {Promise<unknown>}
 * JSON decoded response body future
 *
 * @example
 * ```js
 * const data = await cdn('something');
 * ```
 */
export const cdn = async (path, intercept) => {
  let response = await fetch(`${API_cdn}${path}`);

  handleCfChallenge(response);

  if (!response.ok) {
    throw response;
  }

  if (intercept) {
    response = (await intercept(response)) ?? response;
  }

  return await response.json();
};
