/**
 * @module
 * Similarities between `Movie` and `PictureSet`
 */

import {ABSTRACT, guard} from '@acng/frontend-rubicon';
import {loadImage} from '@acng/frontend-bounty';
import {each} from '@acng/frontend-bounty/std/array.js';
import {POSSIBLE_FSK_LEVELS_DESC} from './fsk.js';
import {fsk} from 'acng/userPool/fsk.js';
import {fskTrans} from 'acng/userPool/0fsk.js';
import {media} from '../service/env.js';
import {getFallbackImageUrl} from 'acng/layout/service/fallback-image.js';
import {get} from '../../amateurPool/service/amateur.js';

/**
 * @implements {GalleryData}
 */
export class Gallery {
  /**
   * @param {GalleryData} data
   */
  constructor(data) {
    ASSERT: {
      guard(this, ABSTRACT(Gallery));

      this.set_id = data.set_id;
      this.user_id = data.user_id;

      /**
       * The *primary* nickname.
       *
       * **DO NOT DISPLAY THIS ONE**.
       *
       * Use {@link Amateur.getNickname} to get the one to display.
       */
      this.nickname = data.nickname;
      this.name = data.name;
      this.description = data.description;
      this.published = data.published;

      /**
       * The *coin* price
       */
      this.price = data.price;
      this.votes = data.votes;
      this.rating = data.rating;
      this.category_codes = data.category_codes;
      this.image_fsk18 = data.image_fsk18;
      this.image_fsk16 = data.image_fsk16;
      this.image_fsk12 = data.image_fsk12;
      this.media_rating = data.media_rating;
      this.media_rating_test = data.media_rating_test;
      this.blocked = data.blocked;
    }
  }

  getAmateur() {
    return get(`${this.user_id}`);
  }

  getImageUrl() {
    const userFsk = fsk.get();

    for (const level of POSSIBLE_FSK_LEVELS_DESC) {
      const value = this[GALLERY_FSK_IMAGE_MAP[level]];

      if (value && userFsk >= level) {
        return `${media.content.pictures}${value}`;
      }
    }

    return getFallbackImageUrl();
  }

  async getImage() {
    return await loadImage(this.getImageUrl(), getFallbackImageUrl());
  }

  getTitle() {
    return fskTrans(this.name);
  }

  getDescription() {
    return fskTrans(this.description);
  }
}

const GALLERY_FSK_IMAGE_MAP = /** @type {const} */ ({
  18: 'image_fsk18',
  16: 'image_fsk16',
  12: 'image_fsk12',
});

/**
 * Drop those gallery data fields.
 *
 * Remove them from the data source (SOLR).
 */
const UNUSED_FIELDS = /** @type {const} */ ([
  'age',
  'age2',
  'gender',
  'gender2',
  'gratis',
  'media_rank',
  'media_score',
  'category_list',
  'images',
  'image_fsk_list',
  'score',
  'list_score',
  '_version_',
]);

/**
 * Remove unused gallery data fields, so that no one uses them.
 *
 * @param {Record<string, unknown>} data
 */
export const deleteUnusedFields = (data) =>
  each(UNUSED_FIELDS, (key) => {
    delete data[key];
  });
