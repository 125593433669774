import angular from 'angular';
import {listen} from "acng/core/context/event-bus.js";
import {isInstanceOf} from '@acng/frontend-bounty/std/value.js';
import {Movie} from 'acng/moviePool/model/movie';
import {isFree} from '../../fmotd/service/free.js';
import {authUser} from 'acng/userPool/context/auth-user';
import {inject} from 'acng/core/service/ng.js';
import {getRating, setRating} from '../service/http.js';
import {Watch} from '@acng/frontend-relativity';
import {ctxGallery} from 'acng/core/context/gallery.js';

angular.module('rating')
  .directive('onswRatingRater', [function () {

    const watchGallery = Watch('[onsw-rating-rater]', ctxGallery);

    /**
     * @param {angular.IScope & {
     *   rating: number;
     *   success: boolean;
     *   rated: boolean;
     *   rateable: boolean;
     *   notRateableMsg: string | false;
     *   rate: () => void;
     *   close: () => void;
     *   onclose: () => void;
     * }} scope
     * @param {JQLite} element
     */
    function link(scope, element) {

      element.addClass('ons-form');

      scope.rating = 0;
      scope.success = false;
      var container = element.find('.outer');
      var rating = container.find('.inner');
      /**
       * @type {Gallery | null}
       */
      var item = null;

      container.on('click', function (ev) {
        if (!item) return;
        var offset = ev.pageX - angular.element(ev.currentTarget).offset().left;
        scope.$applyAsync(function () {
          scope.rating = Math.ceil(offset / (container.width() / 5));
          rating.css({ width: getWidth(scope.rating) + '%' });
        });
      });

      rating.css({ width: getWidth(scope.rating) + '%' });

      scope.rate = function () {
        setRating(element[0], item, scope.rating).then(function () {
          scope.rated = true;
          scope.success = true;
          scope.$digest();
        });
      };

      scope.close = function () {
        if (scope.onclose) {
          scope.onclose();
        }
      };

      watchGallery(element[0], async (_, gallery) => {
        item = gallery;
        scope.rateable = false;
        scope.rated = false;
        checkRateable();
        scope.rated = !!await getRating(element[0], gallery);
        scope.$digest();
      });

      scope.$on('$destroy', listen('gallery.rating', checkRateable));

      async function checkRateable() {
        if (!item || scope.rateable) return;
        scope.notRateableMsg = await blockWithReason(item);
        scope.rateable = !scope.notRateableMsg;
      }

      /**
       * @param {number | undefined} rating
       */
      function getWidth(rating) {
        return 100 * (rating ?? 0) / 5;
      }
    }

    return {
      scope: {
        itemId: '@',
        type: '@',
        onclose: '&?'
      },
      link: link,
      templateUrl: 'template/rating.rater'
    };

  }]);

/**
 * @param {Gallery} gallery
 * @returns {Promise<string | false>}
 */
const blockWithReason = async (gallery) => {
  if (gallery.blocked) {
    return 'rating.notRateable';
  }

  // @ts-expect-error TODO gefactor stock
  if (await gallery.getStock()) {
    return false;
  }

  if (isInstanceOf(gallery, Movie)) {
    if (await isFree(gallery)) {
      if (!authUser || authUser.level < 10) {
        inject('payment').overlay('rating.freeUser');

        return 'rating.freeUser';
      }

      return false;
    }
  }

  return 'rating.notInStock';
};
