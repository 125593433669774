import {AttributeLink, AttributeSetup, task} from '@acng/frontend-stargazer';
import {Watch} from '@acng/frontend-relativity';
import {size} from '@acng/frontend-bounty/std/array.js';
import {now} from '@acng/frontend-bounty/std/date.js';
import {all} from '@acng/frontend-bounty/std/control.js';

import {broadcast} from 'acng/layout/context/list';
import {get as getMovie} from 'acng/moviePool/service/movie.js';
import {get as getAmateur} from 'acng/amateurPool/service/amateur.js';
import {t} from 'acng/locale/config/translate';
import {ctxFreeMovieList, init} from '../context/list.js';
import {closest} from '@acng/frontend-bounty/dom/query.js';
import {IS, guard} from '@acng/frontend-rubicon';
import {HIDDEN, HREF} from '@acng/frontend-bounty/dom/attribute.js';
import {max} from '@acng/frontend-bounty/std/number.js';
import {feature} from '../index.js';
import {remove} from '@acng/frontend-bounty/dom/move.js';

/**
 * @group DOM Attribute
 */
export const FMOTD_LIST = 'fmotd-list';

AttributeSetup(FMOTD_LIST, (name) => {
  const watchList = Watch(`[${name}]`, ctxFreeMovieList);

  /**
   * @param {import('../context/list.js').FreeMovieData[]} freemovies
   * @param {boolean} selectPast
   */
  const filter = (freemovies, selectPast) => {
    const currentTime = now();

    let pivot = -1;

    for (let i = 0, l = size(freemovies); i < l; i++) {
      if (freemovies[i].free_since <= currentTime) {
        pivot = i;
        break;
      }
    }

    return selectPast
      ? freemovies.slice(pivot + 1)
      : freemovies.slice(max(0, pivot - 2), pivot + 1).reverse();
  };

  AttributeLink(name, (element, value) => {
    watchList(element, (element, freemovies) => {
      broadcast(
        element,
        async (offset) => (offset ? [] : filter(freemovies, value == 'past')),
        async (free) => {
          const [movie, amateur, dateSuffix] = await all([
            getMovie(free.id),
            getAmateur(`${free.amateur_id}`),
            t('moviePool.freemovie.time'),
          ]);

          return {
            title: movie.getTitle(),
            description: movie.getDescription(),
            sedcardHash: `#/sedcard/${amateur.nickname}`,
            imageUrl: movie.getImageUrl(),
            nickname: amateur.getNickname(),
            age: amateur.age,
            date:
              free.free_since < now()
                ? new Date(free.free_since).toLocaleDateString(document.body.lang, {
                    month: 'long',
                    day: 'numeric',
                  })
                : free.free_since - now() < 24 * 36e5
                ? dateSuffix
                : new Date(free.free_since).toLocaleDateString(document.body.lang, {weekday: 'long'}),
          };
        },
        async (free, {target}) => {
          ASSERT: guard(target, IS(Element));
          if (!closest(null, target, `[${HREF}]`)) {
            (await getMovie(free.id)).goto(true);
          }
        }
      );
    });
  });

  return (element, _value, compiler) => {
    if (feature[HIDDEN]) {
      remove(element);
      return;
    }

    task(compiler, init());
  };
});
