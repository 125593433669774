import {globalValue} from '@acng/frontend-relativity';
import {isBoolean, undefined} from '@acng/frontend-bounty/std/value.js';
import {now} from '@acng/frontend-bounty/std/date.js';
import {BOOLEAN, guard} from '@acng/frontend-rubicon';

import {get} from '../../core/service/backend.js';
import {onTransaction} from '../../payment/service/event-bus.js';
import {authUser} from '../../userPool/context/auth-user.js';
import {ctxFreeMovieList, init} from '../context/list.js';

/**
 * @param {Movie} movie
 * @returns {Promise<boolean>}
 */
export const isFree = async (movie) => (
  await init(), isFreeForAll(movie) || (isFreeForPremiumOnly(movie) && (await userHasPremiumAccess()))
);

/**
 * @param {Movie} movie
 * @returns {Promise<boolean>}
 */
export const couldBeFree = async (movie) => (
  await init(), isFreeForPremiumOnly(movie) && !(await userHasPremiumAccess())
);

/**
 * @param {Movie} movie
 * @returns {boolean}
 */
const isFreeForAll = (movie) => {
  const t = now();

  for (const free of globalValue(ctxFreeMovieList)) {
    if (free.free_since <= t) {
      return movie.id == free.id;
    }
  }

  return false;
};

/**
 * @param {Movie} movie
 * @returns {boolean}
 */
const isFreeForPremiumOnly = (movie) => {
  const t = now() - 24 * 36e5;

  for (const free of globalValue(ctxFreeMovieList)) {
    if (free.id == movie.id && free.free_since < t) {
      return true;
    }
  }

  return false;
};

const userHasPremiumAccess = (() => {
  /**
   * @type {boolean | undefined}
   */
  let premiumAccess;

  onTransaction(() => (premiumAccess = true));

  /**
   * @returns {Promise<boolean>}
   */
  return async () => {
    if (!authUser) {
      premiumAccess = undefined;

      return false;
    }

    if (!isBoolean(premiumAccess)) {
      premiumAccess = await get('movie/free-premium');
      ASSERT: guard(premiumAccess, BOOLEAN);
    }

    return premiumAccess;
  };
})();
