// bounty, rubicon
import {assign} from '@acng/frontend-bounty/object.js';
import {min} from '@acng/frontend-bounty/std/number.js';
import {FINAL, OWNED, guard} from '@acng/frontend-rubicon';
// enterprise
import {Gallery} from 'acng/core/model/gallery.js';
import {inject} from 'acng/core/service/ng.js';

/**
 * @implements {MovieData}
 */
export class Movie extends Gallery {
  /**
   * @param {MovieData} data
   */
  constructor(data) {
    super(data);

    ASSERT: {
      /**
       * Origin SQL: videos.movies_default
       */
      this.id = data.id;

      /**
       * Pixel width
       */
      this.width = data.width;

      /**
       * Pixel height
       */
      this.height = data.height;

      /**
       * Playtime in seconds
       */
      this.playtime = data.playtime;

      /**
       * File size in bytes
       */
      this.filesize = data.filesize;

      /**
       * FSK requirement for the stream
       */
      this.fsk = data.fsk;

      guard(this, OWNED(data), 'Movie');
      guard(this, FINAL(Movie), 'Movie');
    }

    assign(this, data);
  }

  isHd() {
    return min(this.width, this.height) >= 720;
  }

  /**
   * @param {boolean} [fmotd] set when referrer is a fmotd-list
   * @returns {void}
   */
  goto(fmotd) {
    location.hash = `/sedcard/${this.nickname}/movie/${this.id}${fmotd ? '?fmotd' : ''}`;
  }

  /**
   * @deprecated
   * @param {"nickname" | "recommendation"} type
   * @param {number} limit
   */
  more(type, limit) {
    return inject('http')()
      .get(`/api/movie/more/${this.id}`, {params: {type, limit}, dontIntercept: true})
      .then(/** @param {any} res */ (res) => res.data);
  }
}
