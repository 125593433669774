import {Context, LocalActor} from '@acng/frontend-relativity';

/**
 * @template T
 * @typedef List
 * @property {(offset: number) => Promise<T[]>} more
 * @property {(entry: T) => Promise<Record<string, any>>} config
 * @property {(entry: T, evt: MouseEvent) => any} [click]
 */

/**
 * @type {Local<List<any>>}
 */
export const ctxList = Context();

export const broadcast = (() => {
  const broadcast_ = LocalActor(ctxList);

  /**
   * @template T
   * @param {Element} element
   * @param {List<T>["more"]} more
   * @param {List<T>["config"]} config
   * @param {List<T>["click"]} [click]
   */
  return (element, more, config, click) => {
    broadcast_(element, {more, config, click});
  };
})();
