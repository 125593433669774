// @ts-nocheck
// angular, rubicon
import angular from 'angular';
import {guard} from '@acng/frontend-rubicon';
// enterprise
import {listen} from "../context/event-bus.js";
import {EVENTBUS_PRICE_CHANGE} from "../model/price-change.js";
import {Amateur} from 'acng/amateurPool/model/amateur.js';
import {Movie} from '../../moviePool/model/movie.js';

angular.module('core')
  .factory('price', ['$sessionStorage', '$rootScope', '$injector', function ($sessionStorage, $rootScope, $injector) {

    let Picture, PictureSet, Livecam;
    if ($injector.has('Picture')) {
      Picture = $injector.get('Picture');
    }
    if ($injector.has('PictureSet')) {
      PictureSet = $injector.get('PictureSet');
    }
    if ($injector.has('Livecam')) {
      Livecam = $injector.get('Livecam');
    }

    let prices = { movie: {}, pictureSet: {}, msg: {} };
    let session = $sessionStorage;

    listen('price', (data) => {
      ASSERT: guard(data, EVENTBUS_PRICE_CHANGE);

      const {article} = data;
      const id = article.article_id;
      switch (article.type) {
        case 'movie':
          session[`prices.movie.${id}`] = prices.movie[id] = article.newPrice;break;
        case 'picture':
          Picture.get(id).then(item => {
            session[`prices.pictureSet.${item.set_id}`] = prices.pictureSet[item.set_id] = article.newPrice;
          });break;
        case 'msg':
          session[`prices.msg.${id}`] = prices.msg[id] = article.newPrice;
      }

      $rootScope.$broadcast('price.changed');
    });

    function getPrice(id, type, defaultPrice) {
      if (typeof prices[type][id] == 'undefined') {
        prices[type][id] = session[`prices.${type}.${id}`] || null;
      }

      return prices[type][id] || defaultPrice;
    }

    return {
      get: function (item) {
        if (item instanceof Movie) {
          return getPrice(item.id, 'movie', item.price);
        }
        if (Picture && item instanceof Picture) {
          return getPrice(item.set_id, 'pictureSet', item.price);
        }
        if (PictureSet && item instanceof PictureSet) {
          return getPrice(item.id, 'pictureSet', item.price);
        }
        if (item instanceof Amateur) {
          return Livecam && Livecam.freeMessagesAllowed(item.id) ? 0 : getPrice(item.id, 'msg', item.message_price);
        }

        if (item && item.type == 'sexicon' && item.data instanceof Amateur) {
          return getPrice(item.data.id, 'msg', item.data.message_price);
        }

        return item && item.price ? item.price : null;
      }
    };
  }]);
