import {
  Store,
  add,
  createMap,
  createSet,
  remove,
  set,
  get as getFromMap,
  store,
} from '@acng/frontend-bounty/collection.js';
import {now} from '@acng/frontend-bounty/std/date.js';
import {assign} from '@acng/frontend-bounty/object.js';
import {resolved} from '@acng/frontend-bounty/std/control.js';
import {calculateExpireTime} from '@acng/frontend-bounty/net/fetch.js';
import {ANY, RECORD, STRING, guard} from '@acng/frontend-rubicon';

import {cdn} from '../../core/service/cdn.js';
import {AMATEUR_DATA} from '../model/amateur-data.js';
import {Amateur} from '../model/amateur.js';

const API_prefix = '/api/amateur-profile/';

/**
 * @type {Set<Amateur["id"]>}
 */
export const doNotShow = createSet();

/**
 * @type {Map<Amateur["id"], Promise<Amateur>>}
 */
const idIndex = createMap();

/**
 * @type {Map<Amateur["nickname"], Promise<Amateur>>}
 */
const nicknameIndex = createMap();

/**
 * @type {Map<Amateur["id"], number>}
 */
const expires = createMap();

/**
 * Get an amateur.
 *
 * @param {Amateur["id"]} id
 * @returns {Promise<Amateur>}
 */
export const get = async (id) => {
  const expireTime = getFromMap(expires, id);
  const amateur = await store(idIndex, id, instantiateAmateur);

  if (expireTime && expireTime < now()) {
    const data = await fetchAmateurData(id);

    assign(amateur, data);
    handleAmateurUpdate(amateur);
  }

  return amateur;
};

/**
 * Fetch amateur data and create an amateur instance from it.
 *
 * Remove the {@link idIndex} map entry if the fetch is not ok.
 *
 * @param {Amateur["id"]} id
 * @returns {Promise<Amateur>}
 */
const instantiateAmateur = async (id) => {
  try {
    const amateur = new Amateur(await fetchAmateurData(id));

    handleAmateurUpdate(amateur);

    return amateur;
  } catch (reason) {
    remove(idIndex, id);

    throw reason;
  }
};

/**
 * Call after assign amateur data to amateur object.
 *
 * @param {Amateur} amateur
 * @returns {void}
 */
const handleAmateurUpdate = (amateur) => {
  set(nicknameIndex, amateur.nickname, resolved(amateur));

  if (amateur.doNotShow()) {
    add(doNotShow, amateur.id);

    throw doNotShow;
  } else {
    remove(doNotShow, amateur.id);
  }
};

/**
 * Fetch amateur data.
 *
 * @param {Amateur["id"]} id
 * @returns {Promise<Valid<typeof AMATEUR_DATA>>}
 */
const fetchAmateurData = async (id) => {
  const data = await cdn(`${API_prefix}view?id=${id}`, (response) => {
    const expire = calculateExpireTime(response);

    set(expires, id, expire);
  });
  ASSERT: {
    guard(data, RECORD(ANY, ANY));
    delete data.new_images;
    delete data.preferred_image;
    delete data.livedating;
    delete data.weblog;
    delete data.games;
    delete data.livecam;
    guard(data, AMATEUR_DATA, 'amateur data');
  }

  return data;
};

/**
 * @type {(nickname: Amateur["nickname"]) => Promise<Amateur>}
 */
export const getByNickname = Store(nicknameIndex, async (nickname) => {
  try {
    const data = await cdn(`${API_prefix}id-by-nickname/${nickname}`);
    ASSERT: guard(data, STRING, 'id by nickname');

    return await get(data);
  } catch (reason) {
    remove(nicknameIndex, nickname);

    throw reason;
  }
});
