import {HIDDEN} from '@acng/frontend-bounty/dom/attribute.js';
import {exact} from '@acng/frontend-voyager';

import {userRoute} from '../userPool/config/routes.js';
import {mainView} from '../layout/service/sequence.js';
import {createFrontendFeature} from '../core/model/feature.js';
import {hasFeature} from '../core/service/env.js';
import './attribute/index.js';

export const feature = createFrontendFeature('fmotd');

feature[HIDDEN] = !hasFeature('moviePool');

userRoute.create(() => !feature[HIDDEN], exact('/fmotd'), mainView(feature, 'main'));
