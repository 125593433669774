import angular from 'angular';
import {Movie} from '../model/movie.js';
import {get, getBySetId} from '../service/movie.js';

angular.module('moviePool')

  .factory('Movie', ['http', 'user', 'HttpCache', function (http, user, HttpCache) {

    const cache = new HttpCache();

    Movie.doNotShow = [];

    Movie.random = function (limit, nickname) {
      return http().get('/api/movie/random?limit=' + (limit || 5) + (nickname ? '&nickname=' + nickname : ''), { dontIntercept: true });
    };

    Movie.countByNickname = function (nickname) {
    };

    Movie.list = function (limit, offset, filter, type) {
      return http().get('/api/movie/list' + (type ? `/${type}` : '' ) + '?limit=' + limit + '&offset=' + offset + '&fsk=' + user.getListFsk() + '&filter=' + JSON.stringify(filter), { dontIntercept: true })
        .then(res => {
          if (res.headers('x-bl')) {
            res.data.bl = true;
          }
          return {
            total: parseInt(res.headers('x-numfound')) || 0,
            data: res.data
          };
        });
    };

    Movie.get = function (id) {
      return get(parseInt(id));
    };

    Movie.getBySetId = function (setId) {
      return getBySetId(parseInt(setId));
    };

    Movie.categories = function () {
      return cache.get(
        'c',
        () => http().get('/api/movie/categories', { dontIntercept: true }),
        res => res.data,
        err => {throw err;}
      );
    };

    Movie.isValidId = id => !isNaN(id = parseInt(id)) && id;

    return Movie;
  }])

  .directive('onsdMovies', ['PimpMyRide', 'Movie',(PimpMyRide, Movie) => PimpMyRide(
    'Movies',
    ['limit', 'nickname', 'search', 'categories', 'type'],
    v => {
      return Movie.list(v[0], 0, { nickname: v[1], search: v[2] || '', categories: (v[3] || '').split(',').filter(v => !!v) }, v[4])
        .then(res => {
          Movie.doNotShow.forEach(id => {
            let i = res.data.indexOf(id);
            if (i >= 0) {
              res.data.splice(i, 1);
            }
          });
          return res.data;
        })
    },
    v => v[0] > 0
  )])

  .directive('onsdMoreMovies', ['PimpMyRide', 'Movie', (PimpMyRide, Movie) => PimpMyRide(
    'MoreMovies',
    ['movieId', 'type', 'limit'],
    v => get(Number(v[0])).then(movie => movie.more(v[1], Math.max(0, parseInt(v[2])) || 5)),
    v => Movie.isValidId(v[0]) && /^(nickname|recommendation)$/.test(v[1])
  )]);
